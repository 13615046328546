:root ._fontFamily-299667014{font-family:var(--f-fa);}
:root ._display-inline{display:inline;}
:root ._boxSizing-border-box{box-sizing:border-box;}
:root ._wordWrap-break-word{word-wrap:break-word;}
:root ._whiteSpace-pre-wrap{white-space:pre-wrap;}
:root ._marginTop-0px{margin-top:0px;}
:root ._marginRight-0px{margin-right:0px;}
:root ._marginBottom-0px{margin-bottom:0px;}
:root ._marginLeft-0px{margin-left:0px;}
:root ._fontSize-16px{font-size:16px;}
:root ._lineHeight-24px{line-height:24px;}
:root ._fontWeight-400{font-weight:400;}
:root ._color-quaternary{color:quaternary;}
:root ._display-flex{display:flex;}
:root ._alignItems-stretch{align-items:stretch;}
:root ._flexDirection-column{flex-direction:column;}
:root ._flexBasis-auto{flex-basis:auto;}
:root ._position-relative{position:relative;}
:root ._minHeight-0px{min-height:0px;}
:root ._minWidth-0px{min-width:0px;}
:root ._flexShrink-0{flex-shrink:0;}
:root ._marginBottom-1481558245{margin-bottom:var(--space-6);}
:root ._marginBottom-1481558276{margin-bottom:var(--space-5);}
:root ._color-19330913{color:var(--contentPrimary);}
:root ._flexGrow-1{flex-grow:1;}
:root ._flexShrink-1{flex-shrink:1;}
:root ._flexDirection-row{flex-direction:row;}
:root ._justifyContent-center{justify-content:center;}
:root ._marginBottom-1481558338{margin-bottom:var(--space-3);}
:root ._width-50px{width:50px;}
:root ._marginRight-1481558276{margin-right:var(--space-5);}
:root ._textAlign-center{text-align:center;}
:root ._color-267326628{color:var(--contentNegative);}
:root ._fontSize-14px{font-size:14px;}
:root ._lineHeight-20px{line-height:20px;}
:root ._fontWeight-600{font-weight:600;}
:root ._marginTop-1481558245{margin-top:var(--space-6);}
:root ._alignItems-center{align-items:center;}
:root ._color-secondary{color:secondary;}
:root ._justifyContent-441309761{justify-content:space-between;}
:root ._justifyContent-flex-end{justify-content:flex-end;}