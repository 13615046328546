@font-face {
	font-family: 'Gilroy';
	src: url('/fonts/gilroy-semibold-webfont.woff2') format('woff2');
	font-style: semibold;
	font-weight: 600;
	font-display: swap;
}
@font-face {
	font-family: 'Gilroy';
	src: url('/fonts/gilroy-extrabold-webfont.woff2') format('woff2');
	font-style: extrabold;
	font-weight: 800;
	font-display: swap;
}
@font-face {
	font-family: 'Gilroy';
	src: url('/fonts/gilroy-bold-webfont.woff2') format('woff2');
	font-style: bold;
	font-weight: 700;
	font-display: swap;
}
@font-face {
	font-family: 'Hanken Grotesk';
	src: url('/fonts/HKGroteskPro-Regular.woff2') format('woff2');
	font-style: normal;
	font-weight: 400;
	font-display: swap;
}
@font-face {
	font-family: 'Hanken Grotesk';
	src: url('/fonts/HKGroteskPro-Medium.woff2') format('woff2');
	font-style: normal;
	font-weight: 500;
	font-display: swap;
}
@font-face {
	font-family: 'Hanken Grotesk';
	src: url('/fonts/HKGroteskPro-SemiBold.woff2') format('woff2');
	font-style: normal;
	font-weight: 600;
	font-display: swap;
}
@font-face {
	font-family: 'Hanken Grotesk';
	src: url('/fonts/HKGroteskPro-Bold.woff2') format('woff2');
	font-style: bold;
	font-weight: 700;
	font-display: swap;
}
