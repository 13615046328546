.onfido-sdk-ui-Theme-modalOverlay--after-open {
  z-index: 10;
}

.onfido-sdk-ui-Theme-root .ods-button.-action--secondary:hover:not(:disabled) {
  color: white;
}

.react-toast-notifications__container {
  z-index: 9999 !important;
}

#onfido-sdk {
  z-index: 9999 !important;
  max-width: 100vw;
  min-height: 580px;
  max-height: 80vh;
}

#onfido-sdk iframe {
  min-height: 580px;
  max-height: 68vh;
}